import React from "react"
import cx from "classnames"
import { Link } from "gatsby"
import loadable from "@loadable/component"

import {
  TITLES,
  TELECOM_DEVELOPMENT_SERVICES,
  SERVICES,
  DEV_TECHNOLOGIES,
} from "../data/telecom-software-development"
import { IMG } from "../const"
import Layout from "../components/layout"
import DesignerAndDev from "../components/tech-stack/designer-developer"
import "../assets/styles/commons/_common-kit.scss"
import * as styles from "../assets/styles/telecom-software-development.module.scss"

const Headline = loadable(() => import("../components/headline"))
const TitleBlock = loadable(() => import("../components/title-block"))
const Testimonials = loadable(() => import("../components/testimonials"))
const ServicesList = loadable(() => import("../components/services-list"))
const CaseStudies = loadable(() => import("../components/case-studies"))
const Accordion = loadable(() => import("../components/accordion"))
const ColoredSection = loadable(() => import("../components/colored-section"))
const ContactForm = loadable(() => import("../components/contact-form"))

const TelecomSoftwareDevelopment = () => (
  <Layout
    pageTitle="Telecom Software Development"
    defaultImage={`${IMG.THUMBNAILS}/thumbnail-telecom-software-dev.jpg`}
  >
    {({
      width,
      handleOpenConfirmation,
      isOpenConfirmation,
      setConfirmationOption,
    }) => {
      return (
        <>
          <main className={cx(styles.telecomBlock, "common-kit")}>
            <article>
              <Headline>
                <div className={cx(styles.headline, "headline")}>
                  <div className={cx(styles.headline_grid, "headline_grid")}>
                    <p className={"strongText"}>we are a</p>
                    <h1 className={"withIndent"}>
                      <strong>
                        Telecom
                        <br />
                        software
                      </strong>
                      <br />
                      development company
                    </h1>
                    <div className={styles.positionedText}>
                      <p>
                        We have been delivering emerging technologies in the
                        telecom industry for 10 years
                      </p>
                    </div>
                    <div className={"rightColumn"}>
                      <div>
                        <p>
                          <strong>
                            We have been providing modern telecommunication
                            solutions for companies of all kinds since 2014.
                          </strong>
                        </p>
                        <p>
                          We have done this through transparent service without
                          wasting a fortune for startups, SMBS, and enterprises
                          worldwide.
                        </p>
                      </div>
                      <div>
                        <Link
                          to="#contact-form"
                          className="goldFatLink telecom-contact"
                        >
                          Contact us
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Headline>

              <Testimonials title={TITLES.testimonials} screenWidth={width} />

              <section className={cx("section", styles.solutions)}>
                <TitleBlock options={TITLES.solutions} />
                <ServicesList services={SERVICES.items} />
              </section>

              <section className={cx("section", styles.techStack)}>
                <TitleBlock options={TITLES.tech_stack} />
                <DesignerAndDev developer={DEV_TECHNOLOGIES} />
              </section>

              <CaseStudies
                title={TITLES.case_studies}
                categorySlug="telecommunication"
                screenWidth={width}
              />

              <section className={cx("section", styles.accordionBlock)}>
                <TitleBlock options={TITLES.technology} />
                <div className={styles.accordionWrapper}>
                  <Accordion options={TELECOM_DEVELOPMENT_SERVICES} />
                </div>
              </section>

              <ColoredSection options={TITLES.colored} />
            </article>
          </main>
          <section className="footerForm common-kit">
            <div className="section" id="contact-form">
              <ContactForm
                formStatID="CommonFooter"
                handleOpenConfirmation={handleOpenConfirmation}
                isOpenConfirmation={isOpenConfirmation}
                setConfirmationOption={setConfirmationOption}
              />
            </div>
          </section>
        </>
      )
    }}
  </Layout>
)

export default TelecomSoftwareDevelopment
